import React from 'react';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import MenuWidget from '../Widget/MenuWidget';
import SocialWidget from '../Widget/SocialWidget';
import Newsletter from '../Widget/Newsletter';
import { Link } from 'react-router-dom';
import TextWidget from '../Widget/TextWidget';

const menuDataOne = [
    { title: 'Medical Conditions', href: 'https://getafixmd.com/services/urgent-care/' },
    { title: 'Healthcare Service', href: '/benefits#healthcare' },
    { title: 'Complementary Services', href: '/benefits#complementary' },
    { title: 'Value Added Services', href: '/benefits#valueAdded' }
];

const menuDataTwo = [
    { title: 'About Us', href: 'https://getafixmd.com/about-us/' },
    { title: 'Contact Us', href: 'https://getafixmd.com/contact-us/' },
    { title: 'Commonly Asked Questions', href: 'https://getafixmd.com/privacy-policy/' },
    { title: 'Terms & Conditions', href: 'https://getafixmd.com/terms-and-conditions/' },
    { title: 'Privacy Policy', href: 'https://getafixmd.com/privacy-policy/' }
];

export default function Footer() {
    return (
        <footer className="cs_footer cs_style_1">
            <div className='row'>
                <div className='col-12 col-lg-4' style={{ backgroundColor: '#333333', padding: '80px 0 80px 70px' }}>
                    <img src={'/images/footer_logo.png'} style={{ maxWidth: '100%', height: 'auto', marginBottom: '30px' }} />
                    <div style={{ fontSize: '20px', marginBottom: '20px', color: '#fff', fontWeight: '600' }}>Our Help Line:</div>
                    <div className='d-flex' style={{ marginBottom: '16px' }}>
                        <img src='/images/call-svgrepo-com.svg' />
                        <div style={{ "fontSize": "18px", "lineHeight": "18px", "fontWeight": "300", "paddingLeft": "10px", "color": "#fff" }}>+91 9995159567</div>
                    </div>
                    <div className='d-flex' style={{ marginBottom: '30px' }}>
                        <img src='/images/mail-svgrepo-com.svg' />
                        <div style={{ "fontSize": "18px", "lineHeight": "18px", "fontWeight": "300", "paddingLeft": "10px", "color": "#fff" }}>info@getafixmd.com</div>
                    </div>
                    <SocialWidget />
                </div>
                <div className='col' style={{ backgroundColor: '#D2538C', paddingLeft: '0' }}>
                    <div className='row' style={{ padding: '80px 70px 40px 70px' }}>
                        <div className='col-12 col-lg-4 col-md-4'>
                            <h2 className='cst-footer-heading'>SERVICE LINKS</h2>
                            <MenuWidget data={menuDataOne} />
                        </div>
                        <div className='col-12 col-lg-4 col-md-4'>
                            <h2 className='cst-footer-heading'>QUICK LINKS</h2>
                            <MenuWidget data={menuDataTwo} />
                        </div>
                        <div className='col-12 col-lg-4 col-md-4'>
                            <h2 className='cst-footer-heading'>SUBSCRIBE</h2>
                            <p style={{ "fontSize": "16px", "fontWeight": "400", "color": "#ffffff", "lineHeight": "1.5", "marginBottom": "20px" }}>
                                To get the latest updates from us please provide following details
                            </p>
                            <input className='cst_mail_input' placeholder='Enter your Email' />
                            <input type='submit' value='submit Now' className='cst_mail_btn' />
                        </div>
                    </div>
                    <hr />
                    <div style={{ padding: '20px 40px', textAlign: 'center', color: '#fff', fontSize: '16px' }}>
                        ©2024 GETAFIXMD. ALL RIGHTS RESERVED &nbsp;&nbsp;&nbsp;
                        <span>Powered By </span><a href='https://howincloud.com' target='_blank'><span style={{ fontWeight: '600' }}>Howin</span><span style={{ fontSize: '14px', color: 'red', fontWeight: '550' }}>CLOUD</span></a>
                    </div>
                </div>
            </div>
        </footer>
    );
}
