import React from 'react';
import parse from 'html-react-parser';
import Spacing from '../Spacing';
import { useSelector } from 'react-redux';

export default function SectionHeading({
  title = '',
  titleUp = '',
  titleDown = '',
  subTitle = '',
  center,
  back,
  goToPreNominee,
  goToPreTwo,
  goToPreThree
}) {
  const isHealthy = useSelector((state) => state?.user?.user?.user?.is_healthy);
  const familyDetails = useSelector((state) => state?.user); 



  const familyDetailsData = familyDetails?.user?.user?.family_detials;
  let parsedFamilyDetails = {};
  
  if (typeof familyDetailsData === "string") {
    try {
      parsedFamilyDetails = JSON.parse(familyDetailsData);
    } catch (error) {
      console.error("Error parsing family details:", error);
    }
  } else if (typeof familyDetailsData === "object") {
    parsedFamilyDetails = familyDetailsData;
  }
  
  const secondAdult = parsedFamilyDetails?.secondAdult?.dob ?? "";
  const primaryInsured = parsedFamilyDetails?.primaryInsured?.dob ?? familyDetails?.user?.user?.dob;

  const calculateAge = (dob) => {
    if (!dob || typeof dob !== 'string') {
      return null; 
    }
  
    const today = new Date();
    const [day, month, year] = dob.split("/").map(Number);
    const birthDate = new Date(year, month - 1, day);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
  
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };
  
  const primaryInsuredAge = calculateAge(primaryInsured);
  const secondAdultAge =secondAdult ? calculateAge(secondAdult) : null;
  




  const handlePrevious = () => {
    if (isHealthy == 'NO' || isHealthy == 0 ) {
      goToPreThree();
    } else {
      goToPreNominee();
    }
    if ((primaryInsuredAge > 65 || (secondAdultAge && secondAdultAge > 65))) {
      goToPreTwo();
      return;
    }
  };

  return (
    <div className={`cs_section_heading cs_style_1 ${center ? 'text-center' : ''}`}>
      {title && (
        <div className='d-flex align-items-center'>
          {back && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={'/images/icons/back.svg'} style={{ width: '12px' }} alt='back' onClick={handlePrevious} />
              <h4 style={{ marginBottom: '0px' }} onClick={handlePrevious}>Previous</h4>
            </div>
          )}
          <div style={{ width: '100%', textAlign: 'center' }}>
            <h2 className="cs_section_title cs_fs_50 m-0">{parse(title)}</h2>
          </div>
        </div>
      )}
      {titleDown && (
        <>
          <Spacing md="5" />
          <h3 className="cs_section_subtitle cs_accent_color cs_semibold m-0 cs_accent_color cs_fs_24">
            {parse(titleDown)}
          </h3>
        </>
      )}
      {subTitle && (
        <>
          <Spacing md="25" />
          <p className="m-0">{parse(subTitle)}</p>
        </>
      )}
    </div>
  );
}
