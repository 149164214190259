import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  GET_BOOKINGS_URL,
  ADD_FAMILY_DETAILS_URL,
  ADD_NOMINEES_URL,
  ADD_HEALTH_CONDITION_URL,
  ADD_POLICY_DETAILS_URL,
  ADD_SPECIFIC_ILLNESS_URL,
  APPLY_COUPON_URL,
  CHECKOUT_URL,
  GET_PLANS_URL,
  SAVE_PARTNER_URL,
  SEND_OTP_URL,
  UPDATE_USER_DATA_URL,
  UPDATE_PAYMENT_STATUS_URL,
  VERIFY_OTP_URL,
  GET_DISTRICTS_URL,
  GET_PLACES_URL,
  ADD_ALL_TICKETS,
  GET_ALL_TICKETS,
  UPDATE_PROFILE
} from '../../config/apiUrls';

const initialState = {
  auth_loading: false,
  user: null,
  plans: [],
  policy_details: [],
  family_details: [],
  health_condition: [],
  specific_illness: [],
  coupon_discount: [],
  checkout_data: [],
  tickets: [],
};

export const getPlans = createAsyncThunk(
  'auth/getPlans',
  async (token, thunkAPI) => {
    try {
      const response = await axios.post(GET_PLANS_URL, {
        token,
      });
      return response.data;
    } catch (err) {
      // console.log(err);

      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const getBookings = createAsyncThunk(
  'auth/getBookings',
  async (token, thunkAPI) => {
    try {
      const response = await axios.post(GET_BOOKINGS_URL, {
        token,
      });
      return response.data;
    } catch (err) {
      // console.log(err);

      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const updateUserData = createAsyncThunk(
  'auth/updateUserData',
  async (token, thunkAPI) => {
    try {
      const response = await axios.post(UPDATE_USER_DATA_URL, {
        token,
      });
      return response.data;
    } catch (err) {
      // console.log(err);

      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const updateProfile = createAsyncThunk(
  'auth/updateProfile',
  async (formData, thunkAPI) => {
    try {
      const response = await axios.post(UPDATE_PROFILE, formData);

      return response.data;
    } catch (err) {
      // console.log(err);

      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const sendOtp = createAsyncThunk(
  'auth/sendOtp',
  async (phone, thunkAPI) => {
    try {
      const response = await axios.post(SEND_OTP_URL, {
        phone,
      });
      return response.data;
    } catch (err) {
      // console.log(err);

      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const verifyOtp = createAsyncThunk(
  'auth/verifyOtp',
  async ({ phone, otp, name, thunkAPI }) => {
    try {
      const response = await axios.post(VERIFY_OTP_URL, {
        phone, otp, name
      });
      return response.data;
    } catch (err) {
      // console.log(err);

      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const addPolicyDetails = createAsyncThunk(
  'auth/addPolicyDetails',
  async ({ token, insured, policyNumber, purchaseDate, validUpto, companyName, currentValue, provider, deductable }, thunkAPI) => {
    try {
      const response = await axios.post(ADD_POLICY_DETAILS_URL, {
        token, insured, policyNumber, purchaseDate, validUpto, companyName, currentValue, provider, deductable
      });
      return response.data;
    } catch (err) {
      // console.log(err);

      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const addFamilyDetails = createAsyncThunk(
  'auth/addFamilyDetails',
  async ({ token, insuranceType, familyDetails }, thunkAPI) => {
    try {
      const response = await axios.post(ADD_FAMILY_DETAILS_URL, {
        token, insuranceType, familyDetails
      });
      return response.data;
    } catch (err) {
      // console.log(err);

      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const dispatchNominees = createAsyncThunk(
  'auth/dispatchNominees',
  async ({ token, familyDetails }, thunkAPI) => {
    try {
      const response = await axios.post(ADD_NOMINEES_URL, {
        token, familyDetails
      });
      return response.data;
    } catch (err) {
      // console.log(err);

      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const addHealthCondition = createAsyncThunk(
  'auth/addHealthCondition',
  async ({ token, isHealthy, otherDiseases }, thunkAPI) => {

    try {
      const response = await axios.post(ADD_HEALTH_CONDITION_URL, {
        token, isHealthy, otherDiseases
      });
      return response.data;
    } catch (err) {
      // console.log(err);

      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const addSpecificIllness = createAsyncThunk(
  'auth/addSpecificIllness',
  async ({ token, isSpecificIllness }, thunkAPI) => {

    try {
      const response = await axios.post(ADD_SPECIFIC_ILLNESS_URL, {
        token, isSpecificIllness
      });
      return response.data;
    } catch (err) {
      // console.log(err);

      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const applyCoupon = createAsyncThunk(
  'auth/applyCoupon',
  async ({ token, coupon_code, plan_id }, thunkAPI) => {

    try {
      const response = await axios.post(APPLY_COUPON_URL, {
        token, coupon_code, plan_id
      });
      return response.data;
    } catch (err) {
      // console.log(err);

      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const checkout = createAsyncThunk(
  'auth/checkout',
  async ({ token, coupon_code, plan_id, gst }, thunkAPI) => {

    try {
      const response = await axios.post(CHECKOUT_URL, {
        token, coupon_code, plan_id, gst,
      });
      return response.data;
    } catch (err) {
      // console.log(err);

      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const updatePaymentStatus = createAsyncThunk(
  'auth/updatePaymentStatus',
  async ({ token, id, payment_id }, thunkAPI) => {
    try {
      const response = await axios.post(UPDATE_PAYMENT_STATUS_URL, {
        token, id, payment_id
      });
      return response.data;
    } catch (err) {
      // console.log(err);

      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const getDistricts = createAsyncThunk(
  'auth/getDistricts',
  async (thunkAPI) => {
    try {
      const response = await axios.post(GET_DISTRICTS_URL);
      return response.data;
    } catch (err) {
      // console.log(err);

      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const getPlaces = createAsyncThunk(
  'auth/getPlaces',
  async ({ district_id, type }, thunkAPI) => {
    try {
      const response = await axios.post(GET_PLACES_URL, {
        district_id, type
      });
      return response.data;
    } catch (err) {
      // console.log(err);

      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const savePartner = createAsyncThunk(
  'auth/savePartner',
  async (formData, thunkAPI) => {
    try {
      const response = await axios.post(SAVE_PARTNER_URL, formData);
      return response.data;
    } catch (err) {
      // console.log(err);

      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const getAllTickets = createAsyncThunk(
  'auth/get-all-tickets',
  async (formData, thunkAPI) => {
    try {
      const response = await axios.post(GET_ALL_TICKETS, formData);
      return response.data;
    } catch (err) {
      // console.log(err);

      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);
export const addTicket = createAsyncThunk(
  'auth/add-ticket',
  async (formData, thunkAPI) => {
    try {
      const response = await axios.post(ADD_ALL_TICKETS, formData);
      return response.data;
    } catch (err) {
      // console.log(err);

      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);



const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    logoutUser: state => {
      state.user = [];
      state.plans = [];
      state.policy_details = [];
      state.family_details = [];
      state.health_condition = [];
      state.specific_illness = [];
      state.coupon_discount = [];
      state.checkout_data = [];
    },
    clearLoading: (state, { payload }) => {
      state.auth_loading = false;
    },
    clearPolicyDetails: (state, { payload }) => {
      state.policy_details = [];
    },

    clearFamilyDetails: (state, { payload }) => {
      state.family_details = [];
    },

    clearCoupon: (state, { payload }) => {
      state.coupon_discount = [];
    },
  },
  extraReducers: builder => {
    builder
      .addCase(updateUserData.pending, state => {
        state.auth_loading = true;
      })
      .addCase(updateUserData.fulfilled, (state, action) => {
        state.auth_loading = false;
        state.user = action.payload;
      })
      .addCase(updateUserData.rejected, (state, action) => {
        state.auth_loading = false;
        state.error = action.error.message;
      })
      .addCase(updateProfile.pending, state => {
        state.auth_loading = true;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.auth_loading = false;
        state.user = action.payload;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.auth_loading = false;
        state.error = action.error.message;
      })
     
      .addCase(sendOtp.pending, state => {
        state.auth_loading = true;
      })
      .addCase(sendOtp.fulfilled, (state, action) => {
        state.auth_loading = false;
        state.user = action.payload;
      })
      .addCase(sendOtp.rejected, (state, action) => {
        state.auth_loading = false;
        state.error = action.error.message;
      })
      .addCase(verifyOtp.pending, state => {
        state.auth_loading = true;
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.auth_loading = false;
        state.user = action.payload;
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.auth_loading = false;
        state.error = action.error.message;
      })
      .addCase(addPolicyDetails.pending, state => {
        state.auth_loading = true;
      })
      .addCase(addPolicyDetails.fulfilled, (state, action) => {
        state.auth_loading = false;
        state.user = action.payload;
        state.policy_details = action.payload;
      })
      .addCase(addPolicyDetails.rejected, (state, action) => {
        state.auth_loading = false;
        state.error = action.error.message;
      })
      .addCase(addFamilyDetails.pending, state => {
        state.auth_loading = true;
      })
      .addCase(addFamilyDetails.fulfilled, (state, action) => {
        state.auth_loading = false;
        state.user = action.payload;
        state.family_details = action.payload;
      })
      .addCase(addFamilyDetails.rejected, (state, action) => {
        state.auth_loading = false;
        state.error = action.error.message;
      })
      .addCase(dispatchNominees.pending, state => {
        state.auth_loading = true;
      })
      .addCase(dispatchNominees.fulfilled, (state, action) => {
        state.auth_loading = false;
        state.user = action.payload;
        state.family_details = action.payload;
      })
      .addCase(dispatchNominees.rejected, (state, action) => {
        state.auth_loading = false;
        state.error = action.error.message;
      })
      .addCase(addHealthCondition.pending, state => {
        state.auth_loading = true;
      })
      .addCase(addHealthCondition.fulfilled, (state, action) => {
        state.auth_loading = false;
        state.user = action.payload;
        state.health_condition = action.payload;
      })
      .addCase(addHealthCondition.rejected, (state, action) => {
        state.auth_loading = false;
        state.error = action.error.message;
      })
      .addCase(addSpecificIllness.pending, state => {
        state.auth_loading = true;
      })
      .addCase(addSpecificIllness.fulfilled, (state, action) => {
        state.auth_loading = false;
        state.user = action.payload;
        state.specific_illness = action.payload;
      })
      .addCase(addSpecificIllness.rejected, (state, action) => {
        state.auth_loading = false;
        state.error = action.error.message;
      })
      .addCase(getPlans.pending, state => {
        state.auth_loading = true;
      })
      .addCase(getPlans.fulfilled, (state, action) => {
        state.auth_loading = false;
        state.plans = action.payload;
      })
      .addCase(getPlans.rejected, (state, action) => {
        state.auth_loading = false;
        state.error = action.error.message;
      })
      .addCase(getBookings.pending, state => {
        state.auth_loading = true;
      })
      .addCase(getBookings.fulfilled, (state, action) => {
        state.auth_loading = false;
        state.bookings = action.payload;
      })
      .addCase(getBookings.rejected, (state, action) => {
        state.auth_loading = false;
        state.error = action.error.message;
      })
      .addCase(applyCoupon.pending, state => {
        state.auth_loading = true;
      })
      .addCase(applyCoupon.fulfilled, (state, action) => {
        state.auth_loading = false;
        state.coupon_discount = action.payload;
      })
      .addCase(applyCoupon.rejected, (state, action) => {
        state.auth_loading = false;
        state.error = action.error.message;
      })
      .addCase(checkout.pending, state => {
        state.auth_loading = true;
      })
      .addCase(checkout.fulfilled, (state, action) => {
        state.auth_loading = false;
        state.checkout_data = action.payload;
      })
      .addCase(checkout.rejected, (state, action) => {
        state.auth_loading = false;
        state.error = action.error.message;
      })
      .addCase(updatePaymentStatus.pending, state => {
        state.auth_loading = true;
      })
      .addCase(updatePaymentStatus.fulfilled, (state, action) => {
        state.auth_loading = false;
      })
      .addCase(updatePaymentStatus.rejected, (state, action) => {
        state.auth_loading = false;
        state.error = action.error.message;
      })
      .addCase(savePartner.pending, state => {
        state.auth_loading = true;
      })
      .addCase(savePartner.fulfilled, (state, action) => {
        state.auth_loading = false;
        state.partner = action.payload;
      })
      .addCase(savePartner.rejected, (state, action) => {
        state.auth_loading = false;
        state.error = action.error.message;
      })



      .addCase(getAllTickets.pending, state => {
        state.auth_loading = true;
      })
      .addCase(getAllTickets.fulfilled, (state, action) => {
        state.auth_loading = false;
        state.tickets = action.payload;
      })
      .addCase(getAllTickets.rejected, (state, action) => {
        state.auth_loading = false;
        state.error = action.error.message;
      })



      .addCase(addTicket.pending, state => {
        state.auth_loading = true;
      })
      .addCase(addTicket.fulfilled, (state, action) => {
        state.auth_loading = false;
        state.tickets = action.payload;
      })
      .addCase(addTicket.rejected, (state, action) => {
        state.auth_loading = false;
        state.error = action.error.message;
      })
  },
});
export const { logoutUser, clearLoading, clearPolicyDetails, clearFamilyDetails, clearCoupon } = userSlice.actions;
export default userSlice.reducer;
