import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getDistricts, getPlaces, updateUserData } from "../../redux/user/userSlice";
import Lottie from "react-lottie";
import loading from "../../loading.json";
import { setYear } from "date-fns";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loading,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default function StepTwo({
  consent,
  error,
  message,
  loading,
  isChecked,
  setIsChecked,
  familyDetails,
  setFamilyDetails,
  goToStepThree,
  goToPreTwo,
  user,
  emailvalidation,
}) {
  const dispatch = useDispatch();
  const [districts, setDistricts] = useState([]);
  const [places, setPlaces] = useState([]);

  useEffect(() => {
    dispatch(getDistricts()).then((response) => {
      if (response.payload && response.payload?.success) {
        setDistricts(response.payload?.districts);
      }
    });
  }, []);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  // const _getPlaces = (e) => {
  //   const { name, value } = e.target;

  //   setFamilyDetails({
  //     ...familyDetails,
  //     [name]: value,
  //   });

  //   if (name == "district") {
  //     if (familyDetails.type) {
  //       dispatch(
  //         getPlaces({ district_id: value, type: familyDetails.type })
  //       ).then((response) => {
  //         if (response.payload && response.payload?.success) {
  //           setPlaces(response.payload?.places);
  //         }
  //       });
  //     }
  //   } else {
  //     if (familyDetails.district) {
  //       dispatch(
  //         getPlaces({ district_id: familyDetails.district, type: value })
  //       ).then((response) => {
  //         if (response.payload && response.payload?.success) {
  //           setPlaces(response.payload?.places);
  //         }
  //       });
  //     }
  //   }
  // };

  useEffect(() => {
    if (familyDetails.district) {
      dispatch(
        getPlaces({ district_id: familyDetails.district, type: familyDetails.type })
      ).then((response) => {
        if (response.payload && response.payload?.success) {
          setPlaces(response.payload?.places);
        }
      });
    }
  }, [familyDetails.district, familyDetails.type]);
  

  const _getPlaces =async (e) => {
    const { name, value } = e.target;
  
    setFamilyDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  
 
    if (name === "district") {
      if (familyDetails.type) {
        const resp = await dispatch(getPlaces({ district_id: value, type: familyDetails.type }));
        if (resp.payload?.success) {
          setPlaces(resp.payload.places);
        }
      }
    } else if (name === "type") {
      if (familyDetails.district) {
        const resp = await dispatch(getPlaces({ district_id: familyDetails.district, type: value }));
        if (resp.payload?.success) {
          setPlaces(resp.payload.places);
        }
      }
    }
  };
  

  const handleTypeChange = (e) => {
    const { name, value } = e.target;
    setFamilyDetails({
      ...familyDetails,
      [name]: value,
    });
  };

  const handleFamilyMemberChange = (key, e) => {
    const { name, value } = e.target;
    setFamilyDetails({
      ...familyDetails,
      [key]: {
        ...familyDetails[key],
        [name]: value,
      },
    });
  };

  const days = Array.from({ length: 31 }, (_, i) => String(i + 1).padStart(2, '0'));
  const months = Array.from({ length: 12 }, (_, i) => String(i + 1).padStart(2, '0'));
  const years = Array.from({ length: 100 }, (_, i) => new Date().getFullYear() - i);
  useEffect(() => {
    if (familyDetails?.primaryInsured?.dob) {
      const dob = String(familyDetails.primaryInsured.dob ?? ""); 
      if (dob.includes("/")) {
        const [day, month, year] = dob.split('/');
        setDayP(day);  
        setMonthP(month);  
        setYearP(year);  
      }
    }
    
    if (familyDetails?.secondAdult?.dob) {
      const dob = String(familyDetails.secondAdult.dob ?? ""); 
      if (dob.includes("/")) {
        const [day, month, year] = dob.split('/');
        setDaySecondAdult(day);  
        setMonthSecondAdult(month);  
        setYearSecondAdult(year);  
      }
    }
    
    if (familyDetails?.firstChild?.dob) {
      const dob = String(familyDetails.firstChild.dob ?? ""); 
      if (dob.includes("/")) {
        const [day, month, year] = dob.split('/');
        setDayFirstChild(day);  
        setMonthFirstChild(month);  
        setYearFirstChild(year);  
      }
    }
    
    if (familyDetails?.secondChild?.dob) {
      const dob = String(familyDetails.secondChild.dob ?? ""); ;
      if (dob.includes("/")) {
        const [day, month, year] = dob.split('/');
        setDaySecondChild(day);  
        setMonthSecondChild(month);  
        setYearSecondChild(year);  
      }
    }
    
  }, [familyDetails.primaryInsured.dob, familyDetails.secondAdult.dob, familyDetails.firstChild.dob, familyDetails.secondChild.dob]);
  const DateSelector = ({ label, day, setDay, month, setMonth, year, setYear, updateDate }) => (
    <div className="col-lg-4">
      <label className="cs_input_label cs_heading_color">{label}</label>
      <div className="cs_with_icon_input">
        <select
          value={day}
          onChange={(e) => {
            setDay(e.target.value);
            updateDate(e.target.value, month, year);
          }}
          style={{
            border: '1px solid rgba(210, 83, 140, 0.5)',
            borderRadius: '10px',
            padding: '16px 20px',
            marginRight: '10px'
          }}>
          <option value="">Day</option>
          {days.map((d) => (
            <option key={d} value={d}>
              {d}
            </option>
          ))}
        </select>

        <select
          value={month}
          onChange={(e) => {
            setMonth(e.target.value);
            updateDate(day, e.target.value, year);
          }}
          style={{
            border: '1px solid rgba(210, 83, 140, 0.5)',
            borderRadius: '10px',
            padding: '16px 20px',
            marginRight: '10px'
          }}>
          <option value="">Month</option>
          {months.map((m) => (
            <option key={m} value={m}>
              {m}
            </option>
          ))}
        </select>

        <select
          value={year}
          onChange={(e) => {
            setYear(e.target.value);
            updateDate(day, month, e.target.value);
          }}
          style={{
            border: '1px solid rgba(210, 83, 140, 0.5)',
            borderRadius: '10px',
            padding: '16px 20px'
          }}>
          <option value="">Year</option>
          {years.map((y) => (
            <option key={y} value={y}>
              {y}
            </option>
          ))}
        </select>
      </div>
      <div className="cs_height_42 cs_height_xl_25" />
    </div>
  );


  const [dayP, setDayP] = useState(null);
  const [monthP, setMonthP] = useState(null);
  const [yearP, setYearP] = useState(null);

  const [daySecondAdult, setDaySecondAdult] = useState(null);
  const [monthSecondAdult, setMonthSecondAdult] = useState(null);
  const [yearSecondAdult, setYearSecondAdult] = useState(null);

  const [dayFirstChild, setDayFirstChild] = useState(null);
  const [monthFirstChild, setMonthFirstChild] = useState(null);
  const [yearFirstChild, setYearFirstChild] = useState(null);

  const [daySecondChild, setDaySecondChild] = useState(null);
  const [monthSecondChild, setMonthSecondChild] = useState(null);
  const [yearSecondChild, setYearSecondChild] = useState(null);

 
  const updateDateP = (d, m, y) => {
    if (d && m && y) {
      const date = `${d}/${m}/${y}`;
      handleDateChange("primaryInsured", date);
    }
  };

  
  const handleDateChange = (key, date) => {
    setFamilyDetails({
      ...familyDetails,
      [key]: {
        ...familyDetails[key],
        dob: date,
      },
    });
  };
 
  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <label className="cs_input_label cs_heading_color">
            Who is this insurance policy for?
          </label>
          <div className="cs_radio_group">
            <div className="cs_radio_wrap">
              <input
                className="cs_radio_input"
                type="radio"
                name="insuranceType"
                id="self"
                checked={familyDetails["insuranceType"] == "SELF"}
                value={"SELF"}
                onChange={(a) => handleTypeChange(a)}
              />
              <label className="cs_radio_label" htmlFor="self">
                Just Myself
              </label>
            </div>
            <div className="cs_radio_wrap">
              <input
                className="cs_radio_input"
                type="radio"
                name="insuranceType"
                id="family"
                checked={familyDetails["insuranceType"] == "FAMILY"}
                value={"FAMILY"}
                onChange={(a) => handleTypeChange(a)}
              />
              <label className="cs_radio_label" htmlFor="family">
                Myself and Family (2 Adults & 2 Children)
              </label>
            </div>
          </div>
          <div className="cs_height_42 cs_height_xl_25" />
        </div>
        <form className="row"

        >

          <div className="col-lg-4">
            <label className="cs_input_label cs_heading_color">Full Name</label>
            <input
              type="text"
              name="name"
              className="cs_form_field"
              placeholder="Enter your Full Name"
              value={familyDetails["primaryInsured"]["name"]}
              onChange={(a) => handleFamilyMemberChange("primaryInsured", a)}
            />
            <div className="cs_height_42 cs_height_xl_25" />
          </div>
          <DateSelector
            label="Primary Insured's Date of Birth"
            day={dayP}

            setDay={setDayP}
            month={monthP}
            setMonth={setMonthP}
            year={yearP}
            setYear={setYearP}
            updateDate={updateDateP}
          />
          <div className="col-lg-4">
            <label className="cs_input_label cs_heading_color">
              Your Gender
            </label>
            <div className="cs_radio_group">
              <div className="cs_radio_wrap">
                <input
                  className="cs_radio_input"
                  type="radio"
                  name="gender"
                  id="male"
                  value={"MALE"}
                  checked={familyDetails["primaryInsured"]["gender"] == "MALE"}
                  onChange={(e) =>
                    handleFamilyMemberChange("primaryInsured", e)
                  }
                />
                <label className="cs_radio_label" htmlFor="male">
                  Male
                </label>
              </div>
              <div className="cs_radio_wrap">
                <input
                  className="cs_radio_input"
                  type="radio"
                  name="gender"
                  id="female"
                  value={"FEMALE"}
                  checked={
                    familyDetails["primaryInsured"]["gender"] == "FEMALE"
                  }
                  onChange={(e) =>
                    handleFamilyMemberChange("primaryInsured", e)
                  }
                />
                <label className="cs_radio_label" htmlFor="female">
                  Female
                </label>
              </div>
            </div>
            <div className="cs_height_42 cs_height_xl_25" />
          </div>
          <div className="col-lg-4">
            <label className="cs_input_label cs_heading_color">Address with Pincode</label>
            <textarea
              type="text"
              name="address"
              className="cs_form_field"
              placeholder="Enter your Address with Pincode"
              value={familyDetails["primaryInsured"]["address"]}
              onChange={(a) => handleFamilyMemberChange("primaryInsured", a)}
            >{familyDetails["primaryInsured"]["address"]}</textarea>
            <div className="cs_height_42 cs_height_xl_25" />
          </div>
          <div className="col-lg-4">
        <label className="cs_input_label cs_heading_color">Abha ID (Optional)</label>
        <input
          type="text"
          name="abhaId"
          className="cs_form_field"
          placeholder="Enter your Abha ID"
          // defaultValue={familyDetails["primaryInsured"]["abhaId"]}
          value={familyDetails["primaryInsured"]["abhaId"]}
          onChange={(e) => handleFamilyMemberChange("primaryInsured", e)}
        />
      </div>
          <div className="col-lg-4">
            <label className="cs_input_label cs_heading_color">Email (Optional)</label>
            <input
              type="email"
              name="email"
              className="cs_form_field"
              placeholder="Enter your Email"
              value={familyDetails?.email}
              onChange={(a) => handleTypeChange(a)}
            />
            <div className="cs_height_42 cs_height_xl_25" />
          </div>
        </form>
        {familyDetails.insuranceType == "FAMILY" && (
          <>
            <label
              className="cs_input_label cs_heading_color"
              style={{ fontWeight: "bold" }}
            >
              Family Members' Details
            </label>
            <form className="row">
              <div className="col-lg-3">
                <label className="cs_input_label cs_heading_color">Second Adult Member's Full Name</label>
                <input
                  type="text"
                  name="name"
                  className="cs_form_field"
                  placeholder="Enter the Full Name"
                  value={familyDetails["secondAdult"]["name"]}
                  onChange={(a) => handleFamilyMemberChange("secondAdult", a)}
                />
                <div className="cs_height_42 cs_height_xl_25" />
              </div>
              <DateSelector
                label="Second Adult Member's Date of Birth"
                day={daySecondAdult}
                setDay={setDaySecondAdult}
                month={monthSecondAdult}
                setMonth={setMonthSecondAdult}
                year={yearSecondAdult}
                setYear={setYearSecondAdult}
                updateDate={(d, m, y) => {
                  if (d && m && y) {
                    handleDateChange("secondAdult", `${d}/${m}/${y}`);
                  }
                }}
              />
              <div className="col-lg-2">
                <label className="cs_input_label cs_heading_color">
                  Gender
                </label>
                <div className="cs_radio_group">
                  <div className="cs_radio_wrap">
                    <input
                      className="cs_radio_input"
                      type="radio"
                      name="gender"
                      id="male"
                      value={"MALE"}
                      checked={familyDetails["secondAdult"]["gender"] == "MALE"}
                      onChange={(e) =>
                        handleFamilyMemberChange("secondAdult", e)
                      }
                    />
                    <label className="cs_radio_label" htmlFor="male">
                      Male
                    </label>
                  </div>
                  <div className="cs_radio_wrap">
                    <input
                      className="cs_radio_input"
                      type="radio"
                      name="gender"
                      id="female"
                      value={"FEMALE"}
                      checked={
                        familyDetails["secondAdult"]["gender"] == "FEMALE"
                      }
                      onChange={(e) =>
                        handleFamilyMemberChange("secondAdult", e)
                      }
                    />
                    <label className="cs_radio_label" htmlFor="female">
                      Female
                    </label>
                  </div>
                </div>
                <div className="cs_height_42 cs_height_xl_25" />
              </div>
              <div className="col-lg-3">
                <label className="cs_input_label cs_heading_color">Relationship (with the primary member)</label>
                <div className="cs_with_icon_input">
                  <select
                    value={familyDetails["secondAdult"]["relationship"]}
                    onChange={(a) => handleFamilyMemberChange("secondAdult", a)}
                    name="relationship"
                    style={{
                      border: '1px solid rgba(210, 83, 140, 0.5)',
                      borderRadius: '10px',
                      padding: '16px 20px',
                      marginRight: '10px'
                    }}>
                    <option value=''>
                      Choose Relationship
                    </option>
                    <option value={'Spouse'}>{'Spouse'}</option>
                    {/* <option value={'Child'}>{'Child'}</option> */}
                  </select>
                </div>
                <div className="cs_height_42 cs_height_xl_25" />
              </div>
            </form>
            <form className="row">
              <div className="col-lg-3">
                <label className="cs_input_label cs_heading_color">First Child's Full Name</label>
                <input
                  type="text"
                  name="name"
                  className="cs_form_field"
                  placeholder="Enter the Full Name"
                  value={familyDetails["firstChild"]["name"]}
                  onChange={(a) => handleFamilyMemberChange("firstChild", a)}
                />
                <div className="cs_height_42 cs_height_xl_25" />
              </div>
              <DateSelector
                label="First Child's Date of Birth"
                day={dayFirstChild}
                setDay={setDayFirstChild}
                month={monthFirstChild}
                setMonth={setMonthFirstChild}
                year={yearFirstChild}
                setYear={setYearFirstChild}
                updateDate={(d, m, y) => {
                  if (d && m && y) {
                    handleDateChange("firstChild", `${d}/${m}/${y}`);
                  }
                }}
              />
              <div className="col-lg-2">
                <label className="cs_input_label cs_heading_color">
                  Gender
                </label>
                <div className="cs_radio_group">
                  <div className="cs_radio_wrap">
                    <input
                      className="cs_radio_input"
                      type="radio"
                      name="gender"
                      id="male"
                      value={"MALE"}
                      checked={familyDetails["firstChild"]["gender"] == "MALE"}
                      onChange={(e) =>
                        handleFamilyMemberChange("firstChild", e)
                      }
                    />
                    <label className="cs_radio_label" htmlFor="male">
                      Male
                    </label>
                  </div>
                  <div className="cs_radio_wrap">
                    <input
                      className="cs_radio_input"
                      type="radio"
                      name="gender"
                      id="female"
                      value={"FEMALE"}
                      checked={
                        familyDetails["firstChild"]["gender"] == "FEMALE"
                      }
                      onChange={(e) =>
                        handleFamilyMemberChange("firstChild", e)
                      }
                    />
                    <label className="cs_radio_label" htmlFor="female">
                      Female
                    </label>
                  </div>
                </div>
                <div className="cs_height_42 cs_height_xl_25" />
              </div>
              <div className="col-lg-3">
                <label className="cs_input_label cs_heading_color">Relationship (with the primary member)</label>
                <div className="cs_with_icon_input">
                  <select
                    value={familyDetails["firstChild"]["relationship"]}
                    onChange={(a) => handleFamilyMemberChange("firstChild", a)}
                    name="relationship"
                    style={{
                      border: '1px solid rgba(210, 83, 140, 0.5)',
                      borderRadius: '10px',
                      padding: '16px 20px',
                      marginRight: '10px'
                    }}>
                    <option value=''>
                      Choose Relationship
                    </option>
                    <option value={'Child'}>{'Child'}</option>
                    {/* <option value={'Spouse'}>{'Spouse'}</option> */}
                  </select>
                </div>
                <div className="cs_height_42 cs_height_xl_25" />
              </div>
            </form>
            <form className="row">
              <div className="col-lg-3">
                <label className="cs_input_label cs_heading_color">Second Child's Full Name</label>
                <input
                  type="text"
                  name="name"
                  className="cs_form_field"
                  placeholder="Enter the Full Name"
                  value={familyDetails["secondChild"]["name"]}
                  onChange={(a) => handleFamilyMemberChange("secondChild", a)}
                />
                <div className="cs_height_42 cs_height_xl_25" />
              </div>
              <DateSelector
                label="Second Child's Date of Birth"
                day={daySecondChild}
                setDay={setDaySecondChild}
                month={monthSecondChild}
                setMonth={setMonthSecondChild}
                year={yearSecondChild}
                setYear={setYearSecondChild}
                updateDate={(d, m, y) => {
                  if (d && m && y) {
                    handleDateChange("secondChild", `${d}/${m}/${y}`);
                  }
                }}
              />
              <div className="col-lg-2">
                <label className="cs_input_label cs_heading_color">
                  Gender
                </label>
                <div className="cs_radio_group">
                  <div className="cs_radio_wrap">
                    <input
                      className="cs_radio_input"
                      type="radio"
                      name="gender"
                      id="male"
                      value={"MALE"}
                      checked={familyDetails["secondChild"]["gender"] == "MALE"}
                      onChange={(e) =>
                        handleFamilyMemberChange("secondChild", e)
                      }
                    />
                    <label className="cs_radio_label" htmlFor="male">
                      Male
                    </label>
                  </div>
                  <div className="cs_radio_wrap">
                    <input
                      className="cs_radio_input"
                      type="radio"
                      name="gender"
                      id="female"
                      value={"FEMALE"}
                      checked={
                        familyDetails["secondChild"]["gender"] == "FEMALE"
                      }
                      onChange={(e) =>
                        handleFamilyMemberChange("secondChild", e)
                      }
                    />
                    <label className="cs_radio_label" htmlFor="female">
                      Female
                    </label>
                  </div>
                </div>
                <div className="cs_height_42 cs_height_xl_25" />
              </div>
              <div className="col-lg-3">
                <label className="cs_input_label cs_heading_color">Relationship (with the primary member)</label>
                <div className="cs_with_icon_input">
                  <select
                    value={familyDetails["secondChild"]["relationship"]}
                    onChange={(a) => handleFamilyMemberChange("secondChild", a)}
                    name="relationship"
                    style={{
                      border: '1px solid rgba(210, 83, 140, 0.5)',
                      borderRadius: '10px',
                      padding: '16px 20px',
                      marginRight: '10px'
                    }}>
                    <option value=''>
                      Choose Relationship
                    </option>
                    <option value={'Child'}>{'Child'}</option>
                    {/* <option value={'Spouse'}>{'Spouse'}</option> */}
                  </select>
                </div>
                <div className="cs_height_42 cs_height_xl_25" />
              </div>
            </form>
          </>
        )}
        {/* {(user?.customer_id == null || user?.customer_id?.length < 12) && ( */}
          <>
            <div className="col-lg-4">
              <label className="cs_input_label cs_heading_color">
                District
              </label>
              <select
                value={familyDetails?.district}
                onChange={(a) => _getPlaces(a)}
                name="district"
                className="cs_form_field"
                required
              >
                <option  selected>
                  Choose an Option
                </option>
                {districts?.map((district, index) => (
                  <option value={district?.id} key={index}>
                    {district?.name}
                  </option>
                ))}
              </select>
              <div className="cs_height_42 cs_height_xl_25" />
            </div>
            <div className="col-lg-4">
              <label className="cs_input_label cs_heading_color">Type</label>
              <select
                value={familyDetails?.type}
                onChange={(a) => _getPlaces(a)}
                name="type"
                className="cs_form_field"
                required
              >
                <option  selected>
                  Choose an Option
                </option>
                <option value="PANCHAYAT">Grama Panchayat</option>
                <option value="CORPORATION">Municipal Corporation</option>
                <option value="MUNICIPALITY">Municipality</option>
              </select>
              <div className="cs_height_42 cs_height_xl_25" />
            </div>
            <div className="col-lg-4">
              <label className="cs_input_label cs_heading_color">Place</label>
              <select
                  value={familyDetails?.place}
                onChange={(a) => handleTypeChange(a)}
                name="place"
                className="cs_form_field"
                required
              >
                <option  selected>
                  Choose an Option
                </option>
                {places?.map((place, index) => (
                  <option value={place?.id} key={index}>
                    {place?.name}
                  </option>
                ))}
              </select>
              <div className="cs_height_42 cs_height_xl_25" />
            </div>
          </>
        {/* )} */}
      </div>
      <h6>Disclaimer: Please ensure that all the details provided here are accurate and supported by valid government-issued proof. Any mismatch may result in the rejection of claims.</h6>
      <div>
        <div
          style={{
            maxHeight: "81px",
            overflowY: "scroll",
            border: "1px solid rgb(249 245 247)",
            padding: "10px",
            marginTop: "20px",
          }}
        >
          <div>Consent Wording:</div>
          <ul>
            <li>
              I have read, understood, and hereby accept the T&C, Disclaimer,
              and Privacy Policy of GetAFixMD, available at
              https://getafixmd.com.
            </li>
            <li>
              I/We hereby give consent in relation to the subscription to
              healthcare and wellness programs/products/services offered by
              GetAFixMD.
            </li>
            <li>
              I/We hereby give consent to issue/offer a group health insurance
              policy as an eligible member/subscriber of the healthcare and
              wellness programs/products/services offered by GetAFixMD. In this
              relation, I/We authorize MAGMA HDI and their representatives to
              call, SMS, or WhatsApp to inform and explain the features and
              other terms and conditions of the group health insurance policy
              issued as per the group policy document and healthcare and
              wellness programs/products/services and group health insurance.
              I/We also authorize GetAFixMD and their representatives to call,
              SMS, or WhatsApp to inform and explain the features and other
              terms and conditions of the healthcare and wellness
              programs/products/services offered by GetAFixMD. These consents
              override my/our registration for DNC/NDNC.
            </li>
          </ul>

          <div>Disclaimer:</div>
          <ul>
            <li>
              The information provided on this website cannot substitute the
              advice of a licensed professional. The information and data
              provided on this website are of a general nature and strictly for
              informational purposes to benefit the beneficiaries of the group
              health insurance as members/subscribers of the wellness
              programs/products. Insurance is a subject matter of solicitation.
              The Group Health Insurance Policy will be issued by MAGMA HDI
              General Insurance Company Limited, Magma House, 24 Park Street,
              Kolkata-700016, having IRDAI registration number 149, under master
              policy documents executed between the Insurance Company and
              HEKASINAHU Pvt Ltd – with the brand name GetAFixMD (Master Policy
              Holder). The information and descriptions contained herein are not
              intended to be complete descriptions of all terms of the Group
              Health Insurance Policy. You are requested to exercise your own
              judgment or seek independent licensed professional advice. For
              more details on risk factors, terms, and conditions, please read
              the sales brochure carefully. In no event shall GetAFixMD or its
              employees be responsible or liable for the use of such
              information.
            </li>
          </ul>
        </div>
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
              style={{ marginRight: "10px" }} // Adds space between the checkbox and label
            />
            <label style={{ color: "#D2538C" }}>
              I agree to receive communications with respect to the consent and
              disclaimer detailed above
            </label>
          </div>
        </div>
      </div>
      {loading ? (
        <button className="cs_btn cs_style_1" style={{ marginTop: "20px" }}>
          <span>Continue</span>
          <Lottie options={defaultOptions} width={45} />
        </button>
      ) : (
        <button
          className="cs_btn cs_style_1"
          onClick={goToStepThree}
          style={{ marginTop: "20px" }}
        >
          <span>Continue</span>
          <i>
            <img src={"/images/icons/arrow_white.svg"} alt="Icon" />
            <img src={"/images/icons/arrow_white.svg"} alt="Icon" />
          </i>
        </button>
      )}

      {error && message && (
        <div style={{ color: "red", fontSize: "12px" }}>
          {message}
        </div>
      )}

      {error && !emailvalidation && !consent && !message && (
        <div style={{ color: "red", fontSize: "12px" }}>
          Complete all details and accept the consent
        </div>
      )}

      {emailvalidation && !message && (
        <div style={{ color: "red", fontSize: "12px" }}>
          Complete the details with a valid email address
        </div>
      )}

      {consent && !message && (
        <div style={{ color: "red", fontSize: "12px" }}>
          Please accept the consent wording
        </div>
      )}
    </>
  );
}
