import { useState } from 'react';
// import { Icon } from '@iconify/react';
// import DatePicker from 'react-datepicker';
import ReactSlider from 'react-slider';
import Spacing from '../Spacing';
import Lottie from 'react-lottie';
import loading from '../../loading.json';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};



export default function StepOne({
    error,
    loading,
    insured,
    setInsuredValue,
    policyNumber,
    setPolicyNumber,
    purchaseDate,
    setPurchaseDate,
    validUpto,
    setValidUpto,
    companyName,
    setCompanyName,
    currentValue,
    setCurrentValue,
    provider,
    setProvider,
    deductable,
    setDeductable,
    goToStepTwo,
    goToPreThree
}) {
    const [existingDetails, setExistingDetails] = useState(insured == 'YES' ? true : false);

    const setInsured = (value) => {
        setInsuredValue(value)
        if (value == 'YES') {
            setExistingDetails(true)
        } else {
            setExistingDetails(false)
        }
    }

    // const findNearestPoint = (value) => {
    //     let closest = points.reduce((prev, curr) => {
    //         return (Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev);
    //     });
    //     return closest;
    // };
    
    const points = [3, 4.5, 5, 6];
    const formatNumber = (num) => (Number.isInteger(num) ? num : Number(num.toFixed(2)));

    const stepSize = points.length > 1 ? Math.min(...points.map((p, i) => i > 0 ? Math.abs(p - points[i - 1]) : Infinity)) : 1;
    const handleChange = (value) => {
        const nearestPoint = findNearestPoint(value);
        setDeductable(formatNumber(nearestPoint));
    };
    ;

    const findNearestPoint = (value) => {
        return formatNumber(
            points.reduce((prev, curr) =>
                Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev
            )
        );
    };
    
    
    const handleCurrentValueChange = (e) => {
        setCurrentValue(e.target.value);
    };

    const handleProviderChange = (e) => {
        setProvider(e.target.value);
    };

    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <label className="cs_input_label cs_heading_color">
                        Do you currently have health insurance coverage?
                    </label>
                    <div className="cs_radio_group">
                        <div className="cs_radio_wrap">
                            <input
                                className="cs_radio_input"
                                type="radio"
                                name="insured"
                                id="yes"
                                checked={insured == 'YES'}
                                value={'YES'}
                                onChange={(a) => setInsured(a.target.value)}
                            />
                            <label className="cs_radio_label" htmlFor="yes">
                                Yes
                            </label>
                        </div>
                        <div className="cs_radio_wrap">
                            <input
                                className="cs_radio_input"
                                type="radio"
                                name="insured"
                                id="no"
                                checked={insured == 'NO'}
                                value={'NO'}
                                onChange={(a) => setInsured(a.target.value)}
                            />
                            <label className="cs_radio_label" htmlFor="no">
                                No
                            </label>
                        </div>
                    </div>
                    <div className="cs_height_42 cs_height_xl_25" />
                </div>
                {existingDetails &&
                    <>
                        {/* <div className="col-lg-6">
                            <label className="cs_input_label cs_heading_color">
                                Purchase Date
                            </label>
                            <div className="cs_with_icon_input">
                                <DatePicker
                                    selected={purchaseDate}
                                    onChange={date => setPurchaseDate(date)}
                                    dateFormat="dd/MM/yyyy"
                                    // minDate={new Date()}
                                    isClearable
                                    placeholderText="dd/mm/yyyy"
                                />
                                <i>
                                    <Icon icon="fa6-solid:calendar-days" />
                                </i>
                            </div>
                            <div className="cs_height_42 cs_height_xl_25" />
                        </div>
                        <div className="col-lg-6">
                            <label className="cs_input_label cs_heading_color">
                                Valid Upto
                            </label>
                            <div className="cs_with_icon_input">
                                <DatePicker
                                    selected={validUpto}
                                    onChange={date => setValidUpto(date)}
                                    dateFormat="dd/MM/yyyy"
                                    minDate={new Date()}
                                    isClearable
                                    placeholderText="dd/mm/yyyy"
                                />
                                <i>
                                    <Icon icon="fa6-solid:calendar-days" />
                                </i>
                            </div>
                            <div className="cs_height_42 cs_height_xl_25" />
                        </div> */}
                        <div className="col-lg-6">
                            <label className="cs_input_label cs_heading_color">
                                What is the value of the current health insurance policy? (Optional)
                            </label>
                            <input
                                type="text"
                                className="cs_form_field"
                                placeholder="5 Lakhs"
                                value={currentValue}
                                name='currentValue'
                                id='currentValue'
                                onChange={handleCurrentValueChange}
                            />
                            <div className="cs_height_42 cs_height_xl_25" />
                        </div>
                        <div className="col-lg-6">
                            <label className="cs_input_label cs_heading_color">
                                Who is the Health insurance service provider?
                            </label>
                            <select
                                className='cs_form_field'
                                name="provider"
                                id="provider"
                                value={provider}
                                onChange={handleProviderChange}
                                style={{
                                    border: '1px solid rgba(210, 83, 140, 0.5)',
                                    borderRadius: '10px',
                                    padding: '16px 20px',
                                    marginRight: '10px'
                                }}>
                                <option  selected>Choose Provider</option>
                                <option value="Acko General Insurance Limited">Acko General Insurance Limited</option>
                                <option value="Aditya Birla Health Insurance Co. Ltd.">Aditya Birla Health Insurance Co. Ltd.</option>
                                <option value="Bajaj Allianz General Insurance Company Limited">Bajaj Allianz General Insurance Company Limited</option>
                                <option value="Care Health Insurance Ltd.">Care Health Insurance Ltd.</option>
                                <option value="Cholamandalam MS General Insurance Company Limited">Cholamandalam MS General Insurance Company Limited</option>
                                <option value="Future Generali India Insurance Company Limited">Future Generali India Insurance Company Limited</option>
                                <option value="Galaxy Health Insurance Company Limited">Galaxy Health Insurance Company Limited</option>
                                <option value="Go Digit General Insurance Limited">Go Digit General Insurance Limited</option>
                                <option value="HDFC ERGO General Insurance Company Limited">HDFC ERGO General Insurance Company Limited</option>
                                <option value="ICICI LOMBARD General Insurance Company Limited">ICICI LOMBARD General Insurance Company Limited</option>
                                <option value="IFFCO TOKIO General Insurance Company Limited">IFFCO TOKIO General Insurance Company Limited</option>
                                <option value="Liberty General Insurance Limited">Liberty General Insurance Limited</option>
                                <option value="Magma HDI General Insurance Company Limited">Magma HDI General Insurance Company Limited</option>
                                <option value="Manipal Cigna Health Insurance Company Limited">Manipal Cigna Health Insurance Company Limited</option>
                                <option value="Narayana Health Insurance Ltd.">Narayana Health Insurance Ltd.</option>
                                <option value="National Insurance Company Limited">National Insurance Company Limited</option>
                                <option value="Navi General Insurance Limited">Navi General Insurance Limited</option>
                                <option value="Niva Bupa Health Insurance Co Ltd.">Niva Bupa Health Insurance Co Ltd.</option>
                                <option value="Raheja QBE General Insurance Co. Ltd.">Raheja QBE General Insurance Co. Ltd.</option>
                                <option value="Reliance General Insurance Company Limited">Reliance General Insurance Company Limited</option>
                                <option value="Royal Sundaram General Insurance Company Limited">Royal Sundaram General Insurance Company Limited</option>
                                <option value="SBI General Insurance Company Limited">SBI General Insurance Company Limited</option>
                                <option value="Star Health & Allied Insurance Co.Ltd.">Star Health & Allied Insurance Co.Ltd.</option>
                                <option value="Tata AIG General Insurance Company Limited">Tata AIG General Insurance Company Limited</option>
                                <option value="The New India Assurance Company Limited">The New India Assurance Company Limited</option>
                                <option value="The Oriental Insurance Company Limited">The Oriental Insurance Company Limited</option>
                                <option value="United India Insurance Company Limited">United India Insurance Company Limited</option>
                                <option value="Universal Sompo General Insurance Company Limited">Universal Sompo General Insurance Company Limited</option>
                                <option value="Zuno General Insurance Ltd.">Zuno General Insurance Ltd.</option>
                                <option value="Zurich Kotak General Insurance Company (India) Limited">Zurich Kotak General Insurance Company (India) Limited</option>
                                <option value="Medisep program for Kerala govt employees & pensioners">Medisep program for Kerala govt employees & pensioners</option>
                                <option value="Health insurance coverage from private sector employers for yourself/offsprings">Health insurance coverage from private sector employers for yourself/offsprings</option>
                            </select>
                            <div className="cs_height_42 cs_height_xl_25" />
                        </div>
                        <h5>Compulsory Deductible</h5>
                        <div className="cs_input_label cs_heading_color">
                            Deductible is the amount you pay for covered healthcare services before GetAFixMD complementary insurance plan begins to pay. This may come from your existing insurance or be paid out of pocket.
                        </div>
                        <div className="col-lg-6">
                            <label className="cs_input_label cs_heading_color" style={{ marginBottom: '25px' }}>
                                Please choose the preferred deductible amount from below:
                            </label>
                            <ReactSlider
                              key={deductable} 
                               min={Math.floor(Math.min(...points))} 
                               max={Math.ceil(Math.max(...points))}
                                step={stepSize}
                                value={deductable}
                                marks={[3,4.5,5,6]}
                                name='deductable'
                                id='deductable'
                                onChange={handleChange}
                                renderTrack={({ style, ...props }) => (
                                    <div
                                        {...props}
                                        style={{
                                            ...style,
                                            height: '2px',
                                            background: 'rgba(210, 83, 140, 0.5)'
                                        }}
                                    />
                                )}
                               
                                
                                renderThumb={({ style, ...props }) => (
                                    <div
                                        {...props}
                                        style={{
                                            ...style,
                                            height: "25px",
                                            width: "25px",
                                            backgroundColor: "#D2538C",
                                            borderRadius: "50%",
                                            top: "-12.5px",
                                            color: "#fff",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            fontSize: "12px",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {Number(deductable) % 1 === 0 ? Number(deductable).toFixed(0) : Number(deductable).toString()}
                                    </div>
                                )}
                                renderMark={(props) => (
                                    <div
                                      {...props}
                                      style={{
                                        ...props.style,
                                        height: "8px",
                                        width: "8px",
                                        backgroundColor: "#D2538C", // Pink color
                                        borderRadius: "50%",
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                      }}
                                    />
                                    )}
                                
                                
                                withTracks={true}
                            />
                            <div style={{ color: '#D2538C', marginTop: '25px', fontWeight: 'bold' }}>{deductable} Lakhs</div>
                            <div className="cs_input_label cs_heading_color mt-4">
                                The above amount is the minimum deductible from you.
                            </div>
                        </div>
                    </>
                }
            </div>
            <Spacing md="20" />
            {loading ? (
                <button className="cs_btn cs_style_1">
                    <span>Continue</span>
                    <Lottie options={defaultOptions} width={45} />
                </button>
            ) : (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <button className="cs_btn cs_style_1" onClick={goToPreThree} style={{ marginRight: '10px' }}>
                        <span>Previous</span>
                    </button>
                    <button className="cs_btn cs_style_1" onClick={goToStepTwo}>
                        <span>Continue</span>
                        <i>
                            <img src={'/images/icons/arrow_white.svg'} alt="Icon" />
                            <img src={'/images/icons/arrow_white.svg'} alt="Icon" />
                        </i>
                    </button>
                </div>
            )}
            {error &&
                <div style={{ color: 'red', fontSize: '12px' }}>
                    Please complete the details
                </div>
            }
        </>
    );
}